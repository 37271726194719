
export default {
  data() {
    return {
      project: null,
    };
  },
  props: {
    data: {
      type: Object,
    },
  },
  async fetch() {
    try {
      this.project = await this.$strapi
        .find(`projects`, { name: this.data.project.name })
        .then((r) => r?.[0]);
    } catch (err) {
      console.error(err);
      console.error({ statusCode: 404, message: err.response });
    }
  },
};
