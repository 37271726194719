
export default {
  data() {
    return {
      uniqueKey: null,
    };
  },
  props: {
    content: {
      type: String,
    },
    url: {
      type: String,
    },
    themeColor: {
      type: String,
    },
    external: {
      type: Boolean,
    },
  },
  async mounted() {
    try {
      this.uniqueKey = Math.random().toString(36).substring(7);
    } catch (error) {
      console.log(error);
    }
  },
};
