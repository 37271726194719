
export default {
  data() {
    return {
      technologie: null,
    };
  },
  props: {
    data: {
      type: Object,
    },
  },
  async fetch() {
    try {
      this.technologie = await this.$strapi.find(`technologies/${this.data.slug}`);
    } catch (err) {
      console.log("Card (technologies)");
      console.error(err);
      console.error({ statusCode: 404, message: err.response });
    }
  },
};
