import { render, staticRenderFns } from "./infos.vue?vue&type=template&id=179a1ba3&scoped=true&"
import script from "./infos.vue?vue&type=script&lang=js&"
export * from "./infos.vue?vue&type=script&lang=js&"
import style0 from "./infos.vue?vue&type=style&index=0&id=179a1ba3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "179a1ba3",
  null
  
)

export default component.exports