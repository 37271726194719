
export default {
  props: {
    data: {
      type: Object,
    },
    isLanding: {
      type: Boolean,
      default: false,
    },
  },
};
