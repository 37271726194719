
export default {
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
};
