
export default {
  data() {
    return {
      project: null,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    sectionColor: {
      type: String,
      required: true,
    },
  },
  computed: {
    isVideoContent() {
      return this.project.discoverCard.backgroundContent?.mime.includes(
        "video"
      );
    },
    contentUrl() {
      let url = this.project.discoverCard.backgroundContent.url;
      (this.isVerySmallScreen ||
        this.isSmallScreen ||
        this.isMediumSmallScreen) &&
        (this.project.discoverCard.isReversed
          ? (url = this.project.blocMission.backgroundImageReversed.url)
          : (url = this.project.blocMission.mobileBackgroundImage.url));
      return url;
    },
  },
  async fetch() {
    try {
      this.project = await this.$strapi.find(`projects/${this.data.project.slug}`);
    } catch (err) {
      console.error(err);
      console.error({ statusCode: 404, message: err.response });
    }
  },
};
