
export default {
  head: {
    script: [
      {
        src: "https://webforms.pipedrive.com/f/loader",
      },
    ],
  },
  props: {
    data: {
      type: Object,
    },
  },
};
