import { render, staticRenderFns } from "./References.vue?vue&type=template&id=ec58e99a&scoped=true&"
import script from "./References.vue?vue&type=script&lang=js&"
export * from "./References.vue?vue&type=script&lang=js&"
import style0 from "./References.vue?vue&type=style&index=0&id=ec58e99a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec58e99a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReferencesCard: require('/builds/clients/agily/agily-website-v2/front/components/References/Card.vue').default,GlobalsLink: require('/builds/clients/agily/agily-website-v2/front/components/Globals/Link.vue').default,GlobalsButton: require('/builds/clients/agily/agily-website-v2/front/components/Globals/Button.vue').default,GlobalsContactFormButton: require('/builds/clients/agily/agily-website-v2/front/components/Globals/ContactFormButton.vue').default})
