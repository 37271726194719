
export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      references: [],
      tags: [],
    };
  },
  async fetch() {
    try {
      let query =
        "projects?_sort=created_at:DESC&_limit=4"

      if (this.data.solution) {
        query += `&solutions.id_in=${this.data.solution.id}`;
      }

      if (this.data.technologies) {
        this.data.technologies.forEach((technology) => {
          query += `&technologies.id_eq=${technology.id}`;
        });
      }

      this.references = await this.$strapi.find(query);

      // Add the tags of each project to the tags array
      this.references.forEach((reference) => {
        reference.technologies.forEach((technologie) => {
          this.tags.push({ name: technologie.name, slug: technologie.slug });
        });
      });

      // Remove duplicates from the tags array
      this.tags = this.tags.filter(
        (tag, index, self) =>
          index === self.findIndex((t) => t.name === tag.name)
      );
    } catch (err) {
      console.error(err);
      console.error({ statusCode: 404, message: err.response });
    }
  },
};
