
export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    openComponent() {
      this.open = !this.open;
      console.log("open?", this.open);
    },
  },
};
