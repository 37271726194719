
export default {
  layout: "default",
  head() {
    return {
      title: this.data.metaTitle,
      description: this.data.metaDescription,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.data.metaDescription,
        },
        {
          hid: "keywords",
          name: "keywords",
          content: this.data.keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.data.metaTitle,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: this.data.metaDescription,
        },
        {
          hid: "og:url",
          property: "og:url",
          content: this.$nuxt.$route.path,
        },
        {
          hid: "og:type",
          property: "og:type",
          content: "website",
        },
        { hid: "robots", name: "robots", content: this.$config.robots_tag }
      ],
      script: [
        {
          json: {
            "@context": "https://schema.org",
            "@type": "Website",
            url: this.$nuxt.$route.path,
            name: this.data.schemaJson,
          },
          type: "application/ld+json",
        },
      ],
    };
  },
  computed: {
    isHideContent() {
      return [
        "/landing-jamstack/",
        "/landing-django/",
        "/landing-nodejs/",
        "/landing-nextjs/",
        "/landing-vuejs/",
        "/landing-agence-seo/",
        "/landing-headless-cms/",
        "/landing-python/",
        "/landing-flutter/",
        "/landing-react/",
        "/landing-ruby-on-rails/",
        "/landing-strapi/",
        "/landing-react-native/",
      ].includes(this.$route.path);
    },
  },
  async asyncData({ app, $strapi, store }) {
    let data;
    let header;
    let dropdownLinks = [];
    try {
      data = await $strapi.find("pages/homepage");

      header = await $strapi.find("header");

      header.menu
        .filter((a) => a.__component == "global.dropdown")
        .map((b) =>
          b.categories
            .map((c) => c.links)
            .map((d) =>
              d.map((e) => {
                if (e.url !== "technologie") {
                  return e.url + "/" + e.slug;
                } else {
                  return e.slug;
                }
              })
            )
        )
        .map((f) => f.map((g) => g.map((h) => dropdownLinks.push(h))));
    } catch (err) {
      console.log("pages/index (homepage)");
      console.error(err);
      console.error({ statusCode: 404, message: err.response });
    }

    await store.dispatch("books/getBooks");
    await store.dispatch("blog/getLastBlog", { limit: 3 });

    return { data, header, dropdownLinks };
  },
};
