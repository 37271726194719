
export default {
  props: {
    paddingDesktop: Number,
    paddingMobile: Number,
  },
  data() {
    return {
      width: 0,
    };
  },

  mounted() {
    window.addEventListener("resize", (event) => {
      this.width = event.target.innerWidth;
    });
  },
};
