import { render, staticRenderFns } from "./ArticlesList.vue?vue&type=template&id=0ef6b9d3&scoped=true&"
import script from "./ArticlesList.vue?vue&type=script&lang=js&"
export * from "./ArticlesList.vue?vue&type=script&lang=js&"
import style0 from "./ArticlesList.vue?vue&type=style&index=0&id=0ef6b9d3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef6b9d3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalsButton: require('/builds/clients/agily/agily-website-v2/front/components/Globals/Button.vue').default,GlobalsContactFormButton: require('/builds/clients/agily/agily-website-v2/front/components/Globals/ContactFormButton.vue').default})
