
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

// Check if the center of the element is in viewport
function isInViewport(element) {
  const { top, height } = element.getBoundingClientRect();
  const center = top + height / 2;

  return center > window.innerHeight || center < 0 ? false : true;
}

function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      offsetTop: 0,
      paddings: {},
    };
  },
  computed: {
    windowHeight() {
      if (process.server) {
        return 0;
      }

      return window.innerHeight;
    },
  },
  mounted() {
    this.checkScroll();
    window.addEventListener("scroll", this.watchScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.watchScroll);
  },
  methods: {
    watchScroll(event) {
      if (this.isMediumSmallScreen) {
        return;
      }

      if (!this.hasRequestedAnimationFrame) {
        this.hasRequestedAnimationFrame = true;
        requestAnimationFrame(() => this.checkScroll(event));
      }
    },
    checkScroll() {
      for (const card of this.$refs.cards) {
        const element = card.$el;
        const cardId = card.data.id;

        // Compute left margin of card which should follow the arch
        if (isInViewport(element)) {
          const { top, height } = element.getBoundingClientRect();
          const offsetTop = top + height / 2; // Relative offset of the center of the card
          const offsetRatio = offsetTop / window.innerHeight; // Where is the card relatively to the page (percentage %)
          const angle = (-offsetRatio + 0.5) * 180; // Transform our ratio% into an angle varying between 90deg and -90deg
          const radians = degreesToRadians(angle);

          // Now we have a varying angle depending on the position of the card
          // We can use cosinus to make W A V E S which will allow us to make the cards have a curved trajectory
          const MAX_PADDING_LEFT = 100;
          const padding = MAX_PADDING_LEFT * Math.cos(radians);

          this.$set(this.paddings, cardId, padding);
        } else {
          this.$set(this.paddings, cardId, 0);
        }
      }

      // Arch position
      this.offsetTop = -this.$refs.verticalSwiper.getBoundingClientRect().top;

      this.hasRequestedAnimationFrame = false;
    },
  },
};
