import { render, staticRenderFns } from "./GrandTexte.vue?vue&type=template&id=c9a6edbe&scoped=true&"
import script from "./GrandTexte.vue?vue&type=script&lang=js&"
export * from "./GrandTexte.vue?vue&type=script&lang=js&"
import style0 from "./GrandTexte.vue?vue&type=style&index=0&id=c9a6edbe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9a6edbe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalsRichContent: require('/builds/clients/agily/agily-website-v2/front/components/Globals/RichContent.vue').default,GlobalsButton: require('/builds/clients/agily/agily-website-v2/front/components/Globals/Button.vue').default,GlobalsContactFormButton: require('/builds/clients/agily/agily-website-v2/front/components/Globals/ContactFormButton.vue').default})
