
import BodyComponents from "../BodyComponents.vue";

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      articles: [],
      date: "",
    };
  },
  filters: {
    description(string) {
      return string.substring(0, 200) + "...";
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  async fetch() {
    let query = "articles?_sort=created_at:DESC&_limit=3";
    try {
      if (this.data.author) {
        query += `&author.id_eq=${this.data.author.id}`;
      }
      if (this.data.category) {
        query += `&category.id_in=${this.data.category.id}`;
      }
      this.articles = await this.$strapi.find(query);
    } catch (err) {
      console.log("query was : " + query);
      console.error(err);
      console.error({ statusCode: 404, message: err.response });
    }
  },
  components: { BodyComponents },
};
