
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
