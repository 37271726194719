
export default {
  data() {
    return {
      data: null,
    };
  },
  props: {
    memberSlug: {
      type: String,
    },
  },
  async fetch() {
    try {
      this.data = await this.$strapi.find(`members/${this.memberSlug}`);
    } catch (err) {
      console.error(err);
      console.error({ statusCode: 404, message: err.response });
    }
  },
};
