import { render, staticRenderFns } from "./DiscoverCarousel.vue?vue&type=template&id=c24321f4&scoped=true&"
import script from "./DiscoverCarousel.vue?vue&type=script&lang=js&"
export * from "./DiscoverCarousel.vue?vue&type=script&lang=js&"
import style0 from "./DiscoverCarousel.vue?vue&type=style&index=0&id=c24321f4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c24321f4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BodyDiscoverCard: require('/builds/clients/agily/agily-website-v2/front/components/Body/DiscoverCard.vue').default})
