import { render, staticRenderFns } from "./ParcoursRecrutment.vue?vue&type=template&id=70857723&scoped=true&"
import script from "./ParcoursRecrutment.vue?vue&type=script&lang=js&"
export * from "./ParcoursRecrutment.vue?vue&type=script&lang=js&"
import style0 from "./ParcoursRecrutment.vue?vue&type=style&index=0&id=70857723&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70857723",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TeamInfos: require('/builds/clients/agily/agily-website-v2/front/components/Team/infos.vue').default,TeamIconInfos: require('/builds/clients/agily/agily-website-v2/front/components/Team/icon-infos.vue').default,BodyAvantages: require('/builds/clients/agily/agily-website-v2/front/components/Body/Avantages.vue').default})
