
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    paragraphs() {
      return [
        ...(this.data.quote || "").split(/\n([\n ])*\n/g).filter((p) => p),
        "",
      ];
    },
  },
};
