
export default {
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    backgroundImage() {
      return process.env.TWICPICS_EXTERNAL_URL + this.data.backgroundImage.url;
    },
  },
};
