
import ContactUs from "./ContactUs.vue";

export default {
  props: {
    themeColor: {
      type: String,
    },
  },

  methods: {
    openForm() {
      this.$modal.open({
        id: "ContactUs",
        component: ContactUs,
      });
    },
  },
};
