
export default {
  props: {
    page: null,
    category: null,
  },
  watch: {
    page: (v) => {
      console.log("volume has been updated", v);
    },
  },
  created() {
    console.log("body comp", this.props);
  },
};
