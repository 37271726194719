
export default {
  props: {
    data: Object,
  },
  data(){
    return {
      cases: []
    }
  },
  async fetch() {
    if(this.data.tag === null){
      this.cases = await this.$strapi.find("customer-cases");
    }else{
      this.cases = await this.$strapi.find("customer-cases",  [['Tags.Name', this.data.tag.Name]]);
    }
  }
}
