import { render, staticRenderFns } from "./BannerCasClients.vue?vue&type=template&id=770159c9&scoped=true&"
import script from "./BannerCasClients.vue?vue&type=script&lang=js&"
export * from "./BannerCasClients.vue?vue&type=script&lang=js&"
import style0 from "./BannerCasClients.vue?vue&type=style&index=0&id=770159c9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "770159c9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalsRichContent: require('/builds/clients/agily/agily-website-v2/front/components/Globals/RichContent.vue').default})
